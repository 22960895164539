import BaseService from './base-service';

const compsToPackages = {
  'wixui.RichTextBox': () => Promise.all([import(/* webpackChunkName: "wixui.RichTextBox" */ '../legacy/components/RichTextBox')]),
  'wixui.ToggleSwitch': () => Promise.all([import(/* webpackChunkName: "wixui.ToggleSwitch" */ '../legacy/components/ToggleSwitch')]),
  'wixui.Slider': () => Promise.all([import(/* webpackChunkName: "wixui.Slider" */ '../components/Slider')]),
  'wixui.SearchBox': () => Promise.all([import(/* webpackChunkName: "wixui.SearchBox" */ '../components/SearchBox')]),
  'wixui.Captcha': () => Promise.all([import(/* webpackChunkName: "wixui.Captcha" */ '../components/Captcha')]),
  'wixui.Pagination': () => Promise.all([import(/* webpackChunkName: "wixui.Pagination" */ '../components/Pagination')]),
  'wixui.AddressInput': () => Promise.all([import(/* webpackChunkName: "wixui.AddressInput" */ '../components/AddressInput')]),
  'wixui.StylableLine': () => Promise.all([import(/* webpackChunkName: "wixui.StylableLine" */ '../components/StylableLine')]),
  'wixui.Tags': () => Promise.all([import(/* webpackChunkName: "wixui.Tags" */ '../components/Tags')]),
  'wixui.RatingsInput': () => Promise.all([import(/* webpackChunkName: "wixui.RatingsInput" */ '../components/RatingsInput')]),
  'wixui.TimePicker': () => Promise.all([import(/* webpackChunkName: "wixui.TimePicker" */ '../components/TimePicker')]),
  'wixui.RatingsDisplay': () => Promise.all([import(/* webpackChunkName: "wixui.RatingsDisplay" */ '../components/RatingsDisplay')]),
  'wixui.VideoPlayer': () => Promise.all([import(/* webpackChunkName: "wixui.VideoPlayer" */ '../components/VideoPlayer')]),
  'wixui.LineShareButton': () => Promise.all([import(/* webpackChunkName: "wixui.LineShareButton" */ '../components/LineShareButton')]),
  'wixui.MusicPlayer': () => Promise.all([import(/* webpackChunkName: "wixui.MusicPlayer" */ '../components/MusicPlayer')]),
  'wixui.StylableButton': () => Promise.all([import(/* webpackChunkName: "wixui.StylableButton" */ '../components/StylableButton')]),
  'wixui.ProgressBar': () => Promise.all([import(/* webpackChunkName: "wixui.ProgressBar" */ '../components/ProgressBar')]),
};

module.exports = new BaseService(compsToPackages);
